export type PrometheusMySqlSortingStatsBackendApiProps = {
    created: number;
    sort_merge_passes: number;
    sort_scan: number;
    sort_range: number;
    sort_rows: number;
};

export default class PrometheusMySqlSortingStats {
    readonly created: number;
    readonly sortMergePasses: number;
    readonly sortScan: number;
    readonly sortRange: number;
    readonly sortRows: number;

    constructor(props: PrometheusMySqlSortingStatsBackendApiProps) {
        this.created = props.created;
        this.sortMergePasses = props.sort_merge_passes;
        this.sortScan = props.sort_scan;
        this.sortRange = props.sort_range;
        this.sortRows = props.sort_rows;
    }
}
