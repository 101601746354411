import { ReactElement, useEffect, useState } from 'react';
import ButtonModalForm from '../ButtonModalForm';
import DbUserService, { AddUserForm } from '../../services/DbUserService';
import DbDatabase from '../../types/DbDatabase';
import { notification } from 'antd';
import CcxIconUserAddOutlined from '../ccx/icons/CcxIconUserAddOutlined';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import DeploymentsItem from '../../types/DeploymentsItem';
import { getDatabaseUsernameValidators } from '../../core/validation/databaseValidation';
import { DatabaseType } from '../../types/Db';

type AddDbUserProps = {
    uuid: string;
    onSuccess: Function;
    databases?: DbDatabase[] | undefined;
    currentDeployment?: DeploymentsItem | null | undefined;
};

function AddDbUser({
    onSuccess,
    uuid,
    databases,
    currentDeployment,
}: AddDbUserProps): ReactElement {
    const fieldsSetup = [
        {
            name: ['dbUsername'],
            testId: 'AddDbUserDbUsername',
            required: true,
            label: 'Username',
            groupBy: 'UserCredentials',
            validators: getDatabaseUsernameValidators(
                (currentDeployment?.getServiceName() as DatabaseType) ||
                    DatabaseType.UNKNOWN
            ),
        },
        {
            name: ['dbPassword'],
            testId: 'AddDbUserDbPassword',
            required: true,
            label: 'Password',
            groupBy: 'UserCredentials',
            type: 'password',
        },
        {
            name: ['dbName'],
            testId: 'AddDbUserDbName',
            required: true,
            label: 'Database name',
            placeholder: 'Select a database',
            type: 'select',
            options: [],
        },
        {
            name: ['dbAuthPlugin'],
            testId: 'AddDbAuthPlugin',
            required: true,
            label: 'Authentication plugin',
            placeholder: 'Select authentication plugin',
            type: 'select',
            options: [
                {
                    label: 'caching_sha2_password',
                    value: 'caching_sha2_password',
                },
                {
                    label: 'mysql_native_password',
                    value: 'mysql_native_password',
                },
            ],
            defaultValue: 'caching_sha2_password',
        },
        {
            name: ['allowedHost'],
            testId: 'AddDbUserAllowedHost',
            required: false,
            label: 'Allowed host',
            placeholder: 'Enter the host that is allowed to connect from.',
        },
        {
            name: ['dbUserDbCommandCategories'],
            testId: 'AddDbUserDbCommandCategories',
            required: true,
            label: 'Categories',
            placeholder: '-@dangerous and -@admin always included',
            defaultValue: '+@all',
            validators: [
                {
                    message:
                        'ensure the category  name as a @ sign and starts with a + or -, e.g +@all',
                    pattern:
                        /^\s*(\+@|-@)([^\s^,]+)(?:\s*(?:\+@|-@)([^\s^,]+)\s*)*\s*$/,
                },
            ],
        },
        {
            name: ['dbUserDbCommands'],
            testId: 'AddDbUserDbCommands',
            required: false,
            label: 'Commands',
            placeholder: 'starts with a "+" or "-" (+get or -get)',
            validators: [
                {
                    pattern: /^\s*[+-]([^\s^,]+)(?:\s*[+-]([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure commands starts with a + or - (e.g +get -set)',
                },
            ],
        },
        {
            name: ['dbUserDbChannels'],
            testId: 'AddDbUserDbChannels',
            required: false,
            label: 'Channels',
            placeholder: 'starts with "&" (&channel, or &*)',
            validators: [
                {
                    pattern: /^\s*&([^\s^,]+)(?:\s*&([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure the channel name starts with a & (e.g &channel,  or &*)',
                },
            ],
        },
        {
            name: ['dbUserDbKeys'],
            testId: 'AddDbUserDbKeys',
            required: false,
            label: 'Keys',
            placeholder: 'starts with a "~" (~key, or ~*)',
            validators: [
                {
                    pattern: /^\s*~([^\s^,]+)(?:\s*~([^\s^,]+)\s*)*\s*$/,
                    message:
                        'ensure the key names starts with a ~ (~key,  or  ~*)',
                },
            ],
        },
    ];
    const [fields, setFields] = useState<any>(fieldsSetup);
    const [title, setTitle] = useState<any>('Create new user');

    useEffect(() => {
        if (databases !== undefined && databases?.length > 0) {
            const newFields = fields.map((f: any) => {
                if (f.name && f.name[0] === 'dbName') {
                    f.options = databases
                        ? databases.map((d) => {
                              return {
                                  label: d.databaseName,
                                  value: d.databaseName,
                              };
                          })
                        : [];
                }
                return f;
            });
            setFields(newFields);
        }
    }, [databases]);

    useEffect(() => {
        setTitle('Create Admin user');
        if (
            (currentDeployment?.isPostgreSql() ||
                currentDeployment?.isMSSQL()) &&
            fields
        ) {
            setFields(fields.slice(0, 2));
        } else if (currentDeployment?.isMariaDb() && fields) {
            setFields(fields.slice(0, 3));
        } else if (currentDeployment?.isPercona() && fields) {
            setFields(fields.slice(0, 4));
        } else if (currentDeployment?.isRedis() && fields) {
            setFields([
                fieldsSetup[0],
                fieldsSetup[1],
                fieldsSetup[5],
                fieldsSetup[6],
                fieldsSetup[7],
                fieldsSetup[8],
            ]);
        }
    }, [currentDeployment]);

    const onCancel = () => {
        // do something here
    };

    const doApiRequest = async ({
        dbUsername,
        dbPassword,
        allowedHost,
        dbName,
        dbUserDbCommandCategories,
        dbUserDbCommands,
        dbUserDbKeys,
        dbUserDbChannels,
        isAdmin = true,
        dbAuthPlugin,
    }: AddUserForm) => {
        try {
            await DbUserService.addUser({
                uuid,
                dbUsername,
                dbPassword,
                allowedHost,
                dbName,
                dbPrivileges: [
                    dbUserDbCommandCategories,
                    dbUserDbCommands,
                    dbUserDbKeys,
                    dbUserDbChannels,
                ].join(' '),
                isAdmin,
                dbAuthPlugin,
            });

            notification.open({
                message: 'Add user',
                description: `New user successfully added!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Add user',
                description: `There was an error adding your user. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);

            throw e;
        }
    };

    return (
        <ButtonModalForm
            disabled={!currentDeployment?.operable}
            title={title}
            buttonText={title}
            buttonIcon={<CcxIconUserAddOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            onCancel={onCancel}
            fields={fields}
            submitText="Create"
            formLayout="vertical"
            useGrid={false}
        >
            <span></span>
        </ButtonModalForm>
    );
}

export default AddDbUser;
