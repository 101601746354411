import React, { ReactElement, useEffect, useState } from 'react';
import styles from './DbDashboard.module.less';
import CloudInstance from '../../../types/CloudInstance';
import MysqlConnectionsChart from '../../ccx/charts/MysqlConnectionsChart';
import MysqlDbDashboard from './MysqlDbDashboard';
import PostgreSqlDbDashboard from './PostgreSqlDbDashboard';
import RedisDashboard from './RedisDashboard';
import MssqlDbDashboard from './MssqlDbDashboard';

type DbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    node?: CloudInstance;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function DbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    node,
    hidden,
    displaySummary,
    onLoading,
}: DbDashboardProps): ReactElement {
    return (
        <section className={styles.DbDashboard}>
            {node?.isPostgreSql() && (
                <PostgreSqlDbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
            {node?.isMSSQL() && (
                <MssqlDbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
            {node?.isMySql() && (
                <MysqlDbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
            {node?.isRedis() && (
                <RedisDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    node={node}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
        </section>
    );
}

export default DbDashboard;
