export type PrometheusPostgreSqlUpdateStatsBackendApiProps = {
    created: number;
    pg_stat_database_tup_updated: number;
};

export default class PrometheusPostgreSqlUpdateStats {
    readonly created: number;
    readonly pgStatDatabaseTupUpdated: number;

    constructor(props: PrometheusPostgreSqlUpdateStatsBackendApiProps) {
        this.created = props.created;
        this.pgStatDatabaseTupUpdated = props.pg_stat_database_tup_updated;
    }
}
