export type PrometheusPostgreSqlActiveSessionsStatsBackendApiProps = {
    created: number;
    active: number;
};

export default class PrometheusPostgreSqlActiveSessionsStats {
    readonly created: number;
    readonly active: number;

    constructor(props: PrometheusPostgreSqlActiveSessionsStatsBackendApiProps) {
        this.created = props.created;
        this.active = props.active;
    }
}
