import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppLabeledComponent.module.less';

interface Props extends CcxComponentProps {
    children: ReactElement;
    label: string;
    softText?: boolean;
}

const AppLabeledComponent: React.FC<Props> = ({
    children,
    label,
    softText = true,
    testId = 'AppLabeledComponent',
}) => {
    return (
        <div className={styles.AppLabeledComponent} data-testid={testId}>
            <div
                className={
                    softText
                        ? styles.AppLabeledComponentLabelSoft
                        : styles.AppLabeledComponentLabel
                }
                data-testid={`${testId}Label`}
            >
                {label}
            </div>
            <div
                className={styles.AppLabeledComponentChildren}
                data-testid={`${testId}Children`}
            >
                {children}
            </div>
        </div>
    );
};

export default AppLabeledComponent;
