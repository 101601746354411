export type PrometheusPostgreSqlSelectReturnedStatsBackendApiProps = {
    created: number;
    pg_stat_database_tup_returned: number;
};

export default class PrometheusPostgreSqlSelectReturnedStats {
    readonly created: number;
    readonly pgStatDatabaseTupReturned: number;

    constructor(props: PrometheusPostgreSqlSelectReturnedStatsBackendApiProps) {
        this.created = props.created;
        this.pgStatDatabaseTupReturned = props.pg_stat_database_tup_returned;
    }
}
