import { useState } from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es';
import styles from './VersionRadioButtons.module.less';

interface VersionRadioButtonsProps {
    dbVersions: string[];
    defaultVersion: string;
    onVersionChange: (e: RadioChangeEvent) => void;
    testId?: string;
}

const VersionRadioButtons = ({
    dbVersions,
    defaultVersion,
    onVersionChange,
    testId = 'VersionRadioButtons',
}: VersionRadioButtonsProps) => {
    const [selectedVersion, setSelectedVersion] =
        useState<string>(defaultVersion);

    const onChange = (e: RadioChangeEvent) => {
        setSelectedVersion(e.target.value);
        onVersionChange(e);
    };

    if (!dbVersions || dbVersions.length === 0) {
        return null;
    }

    return (
        <Radio.Group
            data-testid={`${testId}Versions`}
            onChange={onChange}
            name="version"
            className={
                dbVersions.length === 1
                    ? styles.AddServiceVersion
                    : styles.AddServiceVersions
            }
            value={selectedVersion}
        >
            {dbVersions.map((dbVersion: string) => (
                <Radio.Button
                    data-testid={`${testId}VersionsRadio${dbVersion}`}
                    value={dbVersion}
                    key={dbVersion}
                >
                    <span>{dbVersion}</span>
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default VersionRadioButtons;
