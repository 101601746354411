import { ReactElement } from 'react';
import { Tooltip } from 'antd';
import styles from './DataStoreInformation.module.less';
import { Link, useParams } from 'react-router-dom';
import CcxComponentProps from '../../../core/CcxComponent';
import DeploymentsItem from '../../../types/DeploymentsItem';
import ExpandableText from '../../ExpandableText';
import SmallContainer from '../../ccx/common/SmallContainer';
import CopyToClipboardText from '../../CopyToClipboardText';

interface UrlProps {
    projectUuid: string;
}
interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
}

function DataStoreInformation({
    dataStore,
    testId = 'DataStoreInformation',
}: Props): ReactElement {
    const { projectUuid } = useParams<UrlProps>();

    const tooltipContent = (
        <div className={styles.DataStoreInformationTooltip}>
            <div>{dataStore?.getName()}</div>
        </div>
    );

    const isAccessible =
        dataStore?.operable === true ||
        (dataStore?.operable === false && dataStore.isUnreachable()) ||
        dataStore.isUnderMaintenance();

    const top = (
        <div className={styles.DataStoreInformationTop}>
            {isAccessible ? (
                <Link
                    to={`/projects/${projectUuid}/data-stores/${dataStore?.dataStoreUuid}/overview`}
                >
                    <Tooltip placement="bottom" title={tooltipContent}>
                        <div
                            className={`${
                                dataStore?.getName().length > 23
                                    ? styles.DataStoreInformationTopLongName
                                    : ''
                            } ${styles.DataStoreInformationTopName}`}
                        >
                            {dataStore?.getName()}
                        </div>
                    </Tooltip>
                </Link>
            ) : (
                <Tooltip placement="bottom" title={tooltipContent}>
                    <div
                        className={`${
                            dataStore?.getName().length > 23
                                ? styles.DataStoreInformationTopLongName
                                : ''
                        } ${styles.DataStoreInformationTopName}`}
                    >
                        {dataStore?.getName()}
                    </div>
                </Tooltip>
            )}{' '}
        </div>
    );

    const middle = (
        <div className={styles.DataStoreInformationMiddle}>
            <CopyToClipboardText
                text={dataStore.getUUID()}
                preview={
                    dataStore.getUUID().length > 15
                        ? `${dataStore.getUUID().substring(0, 15)}...`
                        : 
                        dataStore.getUUID()
                }
                tooltip={dataStore.getUUID()}
                hoverable={false}
            />
        </div>
    );

    const bottom = (
        <>
            Created:&nbsp;
            <ExpandableText
                collapsedText={dataStore?.createdAt}
                expandedText={dataStore?.createdAtFull}
            />
        </>
    );

    return (
        <SmallContainer
            testId={testId}
            topChildren={top}
            middleChildren={middle}
            bottomChildren={bottom}
        />
    );
}

export default DataStoreInformation;
