import { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import { Col, Row, Space } from 'antd';
import styles from './DataStoreOverview.module.less';
import DataStoreOverviewGeneralInformation from './DataStoreOverviewGeneralInformation';
import { useParams } from 'react-router';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import DataStoreOverviewServicesState from './DataStoreOverviewServicesState';
import DataStoreOverviewNetworkInformation from './DataStoreOverviewNetworkInformation';
import DataStoreOverviewCloudInformation from './DataStoreOverviewCloudInformation';
import useDataStoreServices from '../../../../core/hooks/useDataStoreServices';
import DataStoreOverviewQueriesPerSecond from './DataStoreOverviewQueriesPerSecond';
import { useAppSelector } from '../../../../redux/hooks';
import DataStoreOverviewAccessToServices from './DataStoreOverviewAccessToServices';

interface UrlProps {
    dataStoreUuid: string;
}

interface Props extends CcxComponentProps {}

function DataStoreOverview({
    testId = 'DataStoreOverview',
}: Props): ReactElement {
    const { dataStoreUuid } = useParams<UrlProps>();
    const { datastores, isLoading } = useAppSelector(
        (state) => state.datastores
    );

    const [dataStore, setDataStore] = useState<DeploymentsItem | undefined>(
        undefined
    );

    const { services, loading } = useDataStoreServices({
        dataStoreUuid,
    });

    const isDataLoading = isLoading || loading;

    useEffect(() => {
        if (datastores && datastores?.deployments) {
            const currentDataStore = datastores?.getByUuid(dataStoreUuid);
            if (currentDataStore) setDataStore(currentDataStore);
        }
    }, [datastores, dataStoreUuid]);

    return (
        <div className={styles.DataStoreOverview} data-testid={testId}>
            <Row gutter={16}>
                <Col span={24}>
                    <Space
                        direction="vertical"
                        size="middle"
                        className={styles.DataStoreOverviewSpace}
                    >
                        {(dataStore?.replica_url || dataStore?.primary_url) && (
                            <DataStoreOverviewAccessToServices
                                dataStore={dataStore}
                                services={services}
                                showTitle={true}
                            />
                        )}
                    </Space>
                </Col>
                <Col xs={24} sm={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        className={styles.DataStoreOverviewSpace}
                    >
                        <DataStoreOverviewServicesState
                            loading={isDataLoading}
                            dataStore={dataStore}
                            services={services}
                        />
                        <DataStoreOverviewGeneralInformation
                            dataStore={dataStore}
                        />
                    </Space>
                </Col>
                <Col xs={24} sm={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        className={styles.DataStoreOverviewSpace}
                    >
                        <DataStoreOverviewQueriesPerSecond
                            dataStore={dataStore}
                        />
                        <DataStoreOverviewCloudInformation
                            dataStore={dataStore}
                        />
                        <DataStoreOverviewNetworkInformation
                            dataStore={dataStore}
                        />
                    </Space>
                </Col>
            </Row>
        </div>
    );
}

export default DataStoreOverview;
