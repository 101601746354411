export type PrometheusMySqlAbortedConnectionsStatsBackendApiProps = {
    created: number;
    aborted_connects: number;
    aborted_clients: number;
    access_denied_errors: number;
};

export default class PrometheusMySqlAbortedConnectionsStats {
    readonly created: number;
    readonly abortedConnects: number;
    readonly abortedClients: number;
    readonly accessDeniedErrors: number;

    constructor(props: PrometheusMySqlAbortedConnectionsStatsBackendApiProps) {
        this.created = props.created;
        this.abortedConnects = props.aborted_connects;
        this.abortedClients = props.aborted_clients;
        this.accessDeniedErrors = props.access_denied_errors;
    }
}
