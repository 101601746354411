export type PrometheusMySqlQueriesStatsBackendApiProps = {
    created: number;
    queries: number;
};

export default class PrometheusMySqlQueriesStats {
    readonly created: number;
    readonly queries: number;

    constructor(props: PrometheusMySqlQueriesStatsBackendApiProps) {
        this.created = props.created;
        this.queries = props.queries;
    }
}
