export type PrometheusMySqlDbConnectionsStatsBackendApiProps = {
    created: number;
    thread_connected: number;
    max_used_connections: number;
    max_connections: number;
    aborted_clients: number;
    aborted_connects: number;
    connections: number;
};

export default class PrometheusMySqlDbConnectionsStats {
    readonly created: number;
    readonly threadConnected: number;
    readonly maxUsedConnections: number;
    readonly maxConnections: number;
    readonly abortedClients: number;
    readonly abortedConnects: number;
    readonly connections: number;

    constructor(props: PrometheusMySqlDbConnectionsStatsBackendApiProps) {
        this.created = props.created;
        this.threadConnected = props.thread_connected;
        this.maxUsedConnections = props.max_used_connections;
        this.maxConnections = props.max_connections;
        this.abortedClients = props.aborted_clients;
        this.abortedConnects = props.aborted_connects;
        this.connections = props.connections;
    }
}
