export type PrometheusMySqlTemporaryTablesStatsBackendApiProps = {
    created: number;
    created_tmp_tables: number;
    created_tmp_disk_tables: number;
};

export default class PrometheusMySqlTemporaryTablesStats {
    readonly created: number;
    readonly createdTmpTables: number;
    readonly createdTmpDiskTables: number;

    constructor(props: PrometheusMySqlTemporaryTablesStatsBackendApiProps) {
        this.created = props.created;
        this.createdTmpTables = props.created_tmp_tables;
        this.createdTmpDiskTables = props.created_tmp_disk_tables;
    }
}
