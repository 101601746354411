import ApiService from './ApiService';
import dayjs from 'dayjs';
import { API_STAT } from '../core/CcxEnv';

// constants
const API_URL = API_STAT;
const DEFAULT_STEP = 60; // recommended: match prometheus scrape interval

type PrometheusQuery = {
    query: string;
    step?: number;
};

export default class PrometheusStatService extends ApiService {
    static async getLoadAverage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'loadAverage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getCpuUsage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'cpuUsage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRamUsage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'ramUsage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getDiskSpaceUsage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'diskSpaceUsage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getNetworkUsage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'networkUsage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getDiskIops(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'diskIops',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getDiskIOUtil(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'diskIOUtil',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getDiskThroughput(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'diskThroughput',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlHandlers(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlHandlers',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlHandlerTransactions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlHandlerTransactions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlDbConnections(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlDbConnections',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlQueries(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlQueries',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlScanOperations(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlScanOperations',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlTableLocking(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlTableLocking',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlTemporaryTables(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlTemporaryTables',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlSorting(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlSorting',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlAbortedConnections(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlAbortedConnections',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMySqlMemoryUtilization(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mySqlMemoryUtilization',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMssqlQPS(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mssqlQPS',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getMssqlProcesses(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'mssqlProcesses',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQuestions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQuestions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlSlowQueries(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlSlowQueries',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQueriesHostgroupDistribution(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQueriesHostgroupDistribution',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlLatencyHostgroupDistribution(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlLatencyHostgroupDistribution',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlDataSentHostgroupDistribution(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlDataSentHostgroupDistribution',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlDataReceivedHostgroupDistribution(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlDataReceivedHostgroupDistribution',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlActiveTransactions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlActiveTransactions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQueriesBackend(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQueriesBackend',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlClientConnections(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlClientConnections',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlServerConnections(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlServerConnections',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQueryCacheMemory(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQueryCacheMemory',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQueryCacheGetSet(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQueryCacheGetSet',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlQueryCacheInOutBytes(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlQueryCacheInOutBytes',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getProxySqlMemoryUtilization(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'proxySqlMemoryUtilization',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlSelectFetched(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlSelectFetched',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlInsert(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlInsert',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlUpdate(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlUpdate',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlDelete(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlDelete',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlSelectReturned(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlSelectReturned',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlActiveSessions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlActiveSessions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlIdleSessions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlIdleSessions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlIdleSessionsInTransaction(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlIdleSessionsInTransaction',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlIdleSessionsInTransactionAborted(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlIdleSessionsInTransactionAborted',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlLockTables(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlLockTables',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlCheckpointsRequestedAndTimed(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlCheckpointsRequestedAndTimed',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlCheckpointSyncTime(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlCheckpointSyncTime',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlCheckpointWriteTime(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlCheckpointWriteTime',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlDiskIOUtilization(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlDiskIOUtilization',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlDiskUsage(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlDiskUsage',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getPostgreSqlDiskLatency(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'postgreSqlDiskLatency',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendsUpDown(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendsUpDown',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendServersUpDown(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendServersUpDown',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyTotalInOut(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyTotalInOut',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendTotalBytesIn(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendTotalBytesIn',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendTotalBytesOut(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendTotalBytesOut',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendServersTotalBytesIn(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendServersTotalBytesIn',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendServersTotalBytesOut(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendServersTotalBytesOut',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyCurrentSessionsByBackend(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyCurrentSessionsByBackend',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyCurrentSessionRateBackends(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyCurrentSessionRateBackends',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyCurrentSessionsBackendServers(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyCurrentSessionsBackendServers',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyCurrentSessionRateBackendServers(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyCurrentSessionRateBackendServers',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyMaxSessionBackendServers(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyMaxSessionBackendServers',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxySessions(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxySessions',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendTotalNumberOfConnectionErrors(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendTotalNumberOfConnectionErrors',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendMaxQueuedRequests(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendMaxQueuedRequests',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getHaProxyBackendCurrentQueuedRequests(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'haProxyBackendCurrentQueuedRequests',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisCommandsPerSec(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisCommandsPerSec',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisTotalKeys(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisTotalKeys',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisConnectedClients(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisConnectedClients',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisBlockedClients(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisBlockedClients',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisMemoryUsed(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisMemoryUsed',
            from,
            to,
            DEFAULT_STEP
        );
    }

    static async getRedisSlaveLag(
        uuid: string,
        host_uuid: string,
        from?: string,
        to?: string
    ) {
        return await this.queryPrometheusApi(
            uuid,
            host_uuid,
            'redisSlaveLag',
            from,
            to,
            DEFAULT_STEP
        );
    }

    protected static async returnFormatted(
        response: Promise<any>,
        metrics: string[]
    ) {
        if (!response)
            throw new Error('Empty or invalid prometheus API response');
        const result = await response;
        const data: any[] = [];

        result.forEach((item: any, z: number) => {
            if (item.status === 'success') {
                item.data.result[0].values.forEach((v: any[], i: number) => {
                    if (!data[i]) data[i] = {};
                    data[i][metrics[z]] = parseFloat(v[1]);
                    data[i].created = v[0];
                });
            } else {
                throw new Error(`Invalid status ${item.status}`);
            }
        });

        return data;
    }

    protected static getPrometheusApiQueryString(
        range: boolean = false,
        from: string,
        to: string,
        step?: number
    ): string {
        if (!from || !to)
            throw new Error(
                `Missing parameters in getPrometheusApiQueryString`
            );

        let queryStr = `?range=${range}`;
        queryStr = `${queryStr}&from=${encodeURIComponent(from)}`;
        queryStr = `${queryStr}&to=${encodeURIComponent(to)}`;
        if (step) {
            queryStr = `${queryStr}&step=${encodeURIComponent(step)}`;
        }

        return queryStr;
    }

    protected static async queryPrometheusApi(
        uuid: string,
        host_uuid: string,
        stat: string,
        from?: string,
        to?: string,
        step?: number
    ) {
        const date = new Date();
        const minutes = 1000 * 60;
        date.setTime(date.getTime() - 30 * minutes); // default value
        const start = from ? from : dayjs(date.getTime()).format();
        const end = to ? to : dayjs(new Date().getTime()).format();

        const requestData = {
            data: {
                host_uuid,
                stat,
            },
        };

        return await this.request(
            'POST',
            `stat/${uuid}/prometheus${this.getPrometheusApiQueryString(
                true,
                start,
                end,
                step
            )}`,
            requestData
        );
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
